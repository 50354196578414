import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InstagramImage from "./InstagramImage"
import Link from "../common/Link"
import InstagramIcon from "../common/InstagramIcon"
export default function InstagramBryggeriet() {
  const data = useStaticQuery(graphql`
    query {
      allInstagramContent(
        filter: { username: { eq: "bryggerietsgymnasium" } }
      ) {
        nodes {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                placeholder: DOMINANT_COLOR
                width: 500
              )
            }
          }
          permalink
          media_type
        }
      }
    }
  `)

  const images = data.allInstagramContent.nodes

  return (
    <div>
      <div className="grid grid-cols-2 lg:grid-cols-6 gap-2 lg:gap-4 ">
        <div className="w-full h-full bg-primary text-white flex items-center justify-center">
          {" "}
          <Link
            to="https://www.instagram.com/bryggerietsgymnasium/"
            className="hover:opacity-70 transition-all"
          >
            <div className="flex flex-col items-center gap-2">
              <div className="w-6 lg:w-12">
                <InstagramIcon />
              </div>{" "}
              <span className="font-bold text-sm xl:text-lg underline">
                Följ oss på Instagram
              </span>
            </div>
          </Link>
        </div>
        {images
          .filter(image => image.media_type !== "VIDEO")
          .slice(0, 11)
          .map((image, i) => {
            return <InstagramImage key={i} image={image} />
          })}
      </div>
    </div>
  )
}
