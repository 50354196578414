import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../common/Link"
import SectionHeader from "../common/SectionHeader"
import FeaturedImage from "../common/FeaturedImage"
import Button from "../common/Button"
export default function NewsIndex() {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(limit: 4, sort: { fields: date, order: DESC }) {
        nodes {
          title
          date(formatString: "YYYY.MM.DD")
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    aspectRatio: 1.6
                    quality: 70
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const newsItems = data.allWpPost.nodes
  return (
    <div className="space-y-8">
      <div className="grid xl:grid-cols-4 gap-4 xl:gap-8">
        {newsItems.map((newsItem, i) => {
          return <NewsItem key={i} newsItem={newsItem} />
        })}
      </div>
      <div>
        <Button text="Visa fler" theme="dark" url="/kalendarium" />
      </div>
    </div>
  )
}

function NewsItem({ newsItem }) {
  const { title, date, uri, featuredImage } = newsItem
  return (
    <div className="w-full">
      <Link to={uri} className="group">
        <div className="w-full space-y-4">
          <div>
            <FeaturedImage image={featuredImage} />
          </div>
          <div className="">
            {/* <p className="date">{date}</p> */}
            <h3>{title}</h3>
          </div>
        </div>
      </Link>
    </div>
  )
}
