import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SectionHeader from "../common/SectionHeader"
import { ChevronRightIcon } from "@heroicons/react/solid"

export default function ProfilesIndex() {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(
        filter: { wpParent: { node: {} }, parentDatabaseId: { eq: 81 } }
      ) {
        nodes {
          id
          title
          uri
        }
      }
    }
  `)

  const profiles = data.allWpPage.nodes

  return (
    <div className="space-y-8">
      <SectionHeader text="Profiler" />
      <div className="grid md:grid-cols-2 xl:grid-cols-1 gap-4">
        {profiles.map((profile, i) => {
          return <Profile key={i} profile={profile} />
        })}
      </div>
    </div>
  )
}

function Profile({ profile }) {
  const { title, uri } = profile
  return (
    <Link to={uri} className="group">
      <div className="flex flex-row justify-between bg-black group-hover:bg-primary group-hover:text-white transition-all text-white p-6">
        <h3 className="text-2xl lg:text-3xl">{title}</h3>
        <div className="w-8 text-primary group-hover:text-white">
          <ChevronRightIcon />
        </div>
      </div>
    </Link>
  )
}
