import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Link from "../common/Link"

export default function InstagramImage({ image }) {
  const img = image.localFile.childImageSharp
  return (
    <div className="group overflow-hidden">
      <Link to={image.permalink}>
        <GatsbyImage
          image={getImage(img)}
          className="group-hover:scale-105 transition-all"
        />
      </Link>
    </div>
  )
}
