import React from "react"
import Link from "../common/Link"
import InstagramBryggeriet from "./InstagramBryggeriet"

export default function Instagram() {
  return (
    <div className="bg-black text-white p-2 lg:p-4">
      <div className=" space-y-4">
        <div className="space-y-4">
          <InstagramBryggeriet />
        </div>
      </div>
    </div>
  )
}
