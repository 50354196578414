import React from "react"
import Button from "../common/Button"
import { useStaticQuery, graphql } from "gatsby"

export default function Ingress() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 6432 }) {
        id
        startsida {
          ingress {
            knapptextIngressStartsida
            lankmalIngressStartsida {
              ... on WpPage {
                id
                uri
              }
            }
            rubrikIngressStartsida
            textIngressStartsida
          }
        }
      }
    }
  `)

  const {
    rubrikIngressStartsida,
    textIngressStartsida,
    knapptextIngressStartsida,
    lankmalIngressStartsida,
  } = data.wpPage.startsida.ingress

  return (
    <div className="space-y-4 lg:space-y-8 ">
      <h2 className="text-3xl lg:text-5xl">{rubrikIngressStartsida}</h2>
      <div className="text-xl lg:text-2xl space-y-4 lg:space-y-8">
        <div
          dangerouslySetInnerHTML={{ __html: textIngressStartsida }}
          className="wp-content"
        />
      </div>
      <div>
        <Button
          text={knapptextIngressStartsida || "Läs mer"}
          theme="dark"
          url={lankmalIngressStartsida.uri || "/om-skolan"}
        />
      </div>
    </div>
  )
}
