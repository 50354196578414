import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../components/common/Button"
import SectionHeader from "../components/common/SectionHeader"
import Ingress from "../components/index/Ingress"
import Instagram from "../components/index/Instagram"

import Layout from "../components/layout"
import NewsIndex from "../components/news/NewsIndex"
import ProfilesIndex from "../components/profiles/ProfilesIndex"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Bryggeriets Gymnasium" isHome />
    <div className="">
      <div className="flex flex-col">
        {/* Ingress + profiler */}
        <div className="index-container flex flex-col xl:flex-row gap-8 xl:gap-16 ">
          <div className="flex-1 space-y-8">
            <Ingress />
          </div>
          <div className="flex-1 xl:max-w-2xl">
            <ProfilesIndex />
          </div>
        </div>{" "}
        {/* Inspirationsbild */}
        <div>
          <Instagram />
        </div>
        {/* Nyheter */}
        <div className="index-container ">
          <NewsIndex />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
